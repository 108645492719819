import Vue from "vue"
import Vuex from "vuex";
Vue.use(Vuex);

import ApiService from "@/components/utils/api.service.js"

const store = new Vuex.Store({
    state: {
        realEstateTypeMap: {
            "parcel": [],
            "unit": [],
        },
        sessionsTaskStatuses: {},
        sessionsAppraisalValue: {},
        sessionAppraisalValueAbortControllers: {},
    },
    mutations: {
        setRealEstateTypeMap(state, val) {
            state.realEstateTypeMap["parcel"] = val["parcel"].map(i => {
                return {
                    value: i[0],
                    text: i[1]
                }
            });
            state.realEstateTypeMap["unit"] = val["unit"].map(i => {
                return {
                    value: i[0],
                    text: i[1]
                }
            });
        },
        updateTaskStatus(state, data) {
            Vue.set(state.sessionsTaskStatuses, data.session_id, data.task_status);
        },
        updateTaskStatusItem(state, data) {
            let task_status = state.sessionsTaskStatuses[data.session_id];
            task_status[data.task_name] = data.task_status
            this.commit("updateTaskStatus", {
                session_id: data.session_id,
                task_status: task_status
            });
        },
        updateAppraisalValue(state, data) {
            Vue.set(state.sessionsAppraisalValue, data.session_id, data.task_status);
        },
        updatesSessionAppraisalValueAbortControllers(state, data) {
            const currentAbortController = state.sessionAppraisalValueAbortControllers[data.session_id];
            if (currentAbortController) {
                currentAbortController.abort();
            }
            Vue.set(state.sessionAppraisalValueAbortControllers, data.session_id, new AbortController());
        },
    },
    getters: {
        getSingleSessionTaskStatus: (state) => (session_id) => {
            return state.sessionsTaskStatuses[session_id];
        },
        getSessionAppraisalValue: (state) => (session_id) => {
            return state.sessionsAppraisalValue[session_id];
        },
    },
    actions: {
        retrieveRealEstateTypeMap(context) {
            ApiService
                .get(`/amas/real-estate/api/type-choices`)
                .then(response => {
                    context.commit("setRealEstateTypeMap", response.data);
                })
                .catch(error => {
                    throw error;
                })
        },
        retrieveSessionTaskStatus(context, session_id) {
            context.commit("updateTaskStatus", {
                session_id: session_id,
                task_status: "loading"
            });
            ApiService
                .get(`/amas/analysis/analysis/api/${session_id}/task/status`)
                .then(response => {
                    context.commit("updateTaskStatus", {
                        session_id: session_id,
                        task_status: response.data
                    });
                })
        },
        retrieveSessionTaskStatusItem(context, {session_id, task_name}) {
            context.commit("updateTaskStatusItem", {
                session_id: session_id,
                task_name: task_name,
                task_status: "loading"
            });
            ApiService
                .get(`/amas/analysis/analysis/api/${session_id}/task/status`, { 
                    params: {
                        name: task_name
                    }
                })
                .then(response => {
                    context.commit("updateTaskStatusItem", {
                        session_id: session_id,
                        task_name: task_name,
                        task_status: response.data
                    });
                })
        },
        retrieveSessionAppraisalValue(context, session_id) {
            context.commit("updatesSessionAppraisalValueAbortControllers", {session_id: session_id});

            context.commit("updateAppraisalValue", {
                session_id: session_id,
                task_status: "loading"
            });

            // NOTE: abort controller doesn't work with ApiService for some reason...
            fetch(`/amas/analysis/analysis/api/${session_id}/appraisal-value`, {
                method: "GET",
                signal: context.state.sessionAppraisalValueAbortControllers[session_id].signal,
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(data => {
                    context.commit("updateAppraisalValue", {
                        session_id: session_id,
                        task_status: data
                    });
                })
                .catch(error => {
                    if (error.name !== "AbortError") {
                        throw error;
                    }
                })
        },
    }
})
export default store;
