<template>
    <div>
        <BaseInput
            :prependIconClass="prependIconClass"
            :appendIconClass="appendIconClass"
            :prependIcon="prependIcon"
            :appendIcon="appendIcon"
            @prepend-icon-click="$emit('prepend-icon-click')"
            @append-icon-click="$emit('append-icon-click')"
        >
            <textarea
                v-model="context.model"
                v-bind="context.attributes"
                :data-type="context.type"
                :type="context.type"
            />
        </BaseInput>
    </div>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
import baseinputmixin from "../mixins/baseinputmixin";
import BaseInput from "./BaseInput.vue";

export default {
    name: "TextAreaInput",
    mixins: [ FormulateInputMixin, baseinputmixin ],
    components: {
        BaseInput,
    },
}
</script>
