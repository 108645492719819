<template>
    <div>
        <div class="statistics-holder">
            <div
                v-for="(stats, index) in statistics"
                :key="index"
                :class="`badge-${stats.color}`"
                :style="`width: ${stats.value / total * 100}%`"

                v-tooltip="`${stats.title}: ${stats.value}`"
            >
                {{ stats.value }}
            </div>
            
        </div>
        <span v-if="latestOrderItemComment(order)" class="text-muted latest-comment" v-tooltip="'Zadnji komentar'">
            <i class="fa fa-comment m-r-5"></i>
            {{ formatDate(latestOrderItemComment(order).updated_at, true) }}
        </span>
    </div>
</template>

<script>
import { formatDate } from "./utils"

import orderitemstatusmixin from "./mixins/orderitemstatusmixin";

export default {
    name: "OrderStatistics",
    mixins: [ orderitemstatusmixin ],
    props: {
        order: {
            type: Object,
            required: true,
        }
    },
    computed: {
        statistics() {
            return Object.values(this.orderItemStatuses)
                .filter(status => this.getOrderItems(this.order, status).length > 0)
                .map(status => {
                    const order_items = this.getOrderItems(this.order, status);
                    const firstTask = order_items[0].task;
                    return {
                        title: firstTask.status_display,
                        value: order_items.length,
                        color: (firstTask.task_status.name === this.orderItemStatuses.STATUS_IN_PREPARATION) ?
                            "purple" : firstTask.task_status.color,
                    }
                });
        },
        total() {
            return this.statistics
                .reduce((acc, item) => acc + item.value, 0);
        },
        
    },
    methods: {
        formatDate,
        getOrderItems: function(order, task_status_name=null) {
            // Return all order items that have task_status.name == task_status_name
            let order_items = order.orderitem_set;
            if (task_status_name) {
                order_items = order_items.filter(order_item => {
                    return order_item.task.task_status.name === task_status_name;
                });
            }
            return order_items;
        },
        allOrderItemsComments: function(order) {
            let order_items = this.getOrderItems(order);
            let comments = order_items.map(order_item => order_item.orderitemcomment_set);
            comments = comments.flat();
            comments = comments.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
            return comments;
        },
        latestOrderItemComment: function(order) {
            // Return the latest order item comment or null if there are no comments
            let comments = this.allOrderItemsComments(order);
            return comments.length > 0 ? comments[0] : null;
        }
    },
}
</script>

<style scoped>
.statistics-holder {
    min-width: 150px;
    width: 100%;
    height: 18px;
    display: flex;

    border-radius: 20px;
    overflow: hidden;
    cursor: default;
}
.statistics-holder > div {
    text-align: center;
    color: #fff;
}
span.latest-comment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}
</style>
