<template>
     <div :class="['vld-parent', {'pin-cursor': editingLocationOfRealEstate !== null}]" style="min-height: 600px">

        <loading 
            :active="loading" 
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div class="row">
            <div class="col-md-12">
                <table class="table table-responsive">
                    <thead>
                        <tr>
                            <th colspan="2">{{$t("general:re_key")}}*</th>
                            <th>{{$t("real_estate:building:on_parcel")}}*</th>
                            <th>{{$t("general:address")}}</th>
                            <th class="text-nowrap">{{$t("task_details:manage:leading")}} <help-icon slug="predmet-vrednotenja-vodilna-nepremicnina" /></th>
                            <th>{{$t("general:real_estate")}}</th>
                            <th>{{$t("task_details:manage:considered_re_type")}}</th>
                            <th>{{$t("task_details:manage:considered_size")}}*</th>
                            <th>{{$t("general:year:built")}}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, index) in selected_subject.real_estates" :key="`row-${index}`">
                            <!-- ID znak -->
                            <td class="text-nowrap">
                                <div v-if="!isEditableField(item, 're_key')">
                                    <re-open-modal :re_key="item.re_key" />
                                </div>
                                <base-input-field
                                    v-else-if="item.real_estate.is_parcel"
                                    v-model="item.re_key"
                                    :placeholder="$t('general:re_key')"
                                    size="sm"
                                />
                                <div v-else>
                                    <span>{{ item.re_key }}</span>
                                </div>
                                <span
                                    v-if="subject.real_estates.length > 1"
                                    class="text-danger small custom-cursor-pointer" 
                                    v-tooltip="$t('task_details:manage:remove_from_validation')"
                                    @click.prevent="removeRealEstate(item)"
                                >
                                    {{$t("general:remove")}}
                                </span>
                                <span
                                    v-if="item.real_estate && item.real_estate.is_parcel"
                                    class="text-primary small custom-cursor-pointer" 
                                    v-tooltip="$t('real_estate:building:add')"
                                    @click="addCustomRealEstateRow(false, item)"
                                >
                                    {{ $t('real_estate:building:add') }}
                                </span>
                                <div
                                    v-if="item.real_estate && item.real_estate.is_parcel"
                                    class="text-primary small custom-cursor-pointer" 
                                    @click="openModal(item)"
                                >
                                    {{ $t("real_estate:building:add_from_possession") }}
                                </div>
                            </td>
                            
                            <!-- Lokacija -->
                            <td>
                                <div
                                    v-if="isLocationForRealEstateSet(item)"
                                    class="custom-cursor-pointer"
                                    v-tooltip="$t('real_estate:location:defined')"
                                    @click="setLocationEditing(item)"
                                >
                                    <i class="fa fa-map-marker text-info map-marker-icon"></i>
                                </div>
                                <div
                                    v-else
                                    class="custom-cursor-pointer"
                                    v-tooltip="$t('real_estate:location:undefined')"
                                    @click="setLocationEditing(item)"
                                >
                                    <i class="fa fa-map-marker text-danger map-marker-icon"></i>
                                </div>
                            </td>
                            
                            <!-- Na parcelah -->
                            <td>
                                <div v-if="item.real_estate && !item.real_estate.is_parcel && item.custom_data && item.custom_data.building_on_parcels">
                                    <base-input-field
                                        v-model="item.custom_data.building_on_parcels[0]"
                                        size="sm"
                                        @input="onBuildingOnParcelsChange(item)"
                                    />
                                </div>
                            </td>

                            <!-- Naslov -->
                            <td>
                                <base-input-field
                                    v-if="!item.real_estate.is_parcel"
                                    v-model="item.real_estate.address"
                                    :placeholder="$t('general:address')"
                                    size="sm"
                                    @input="$emit('set-session-default-name')"
                                />
                            </td>

                            <!-- Vodilna -->
                            <td>
                                <input type="radio"
                                    name="leading"
                                    :checked="item.main_property"
                                    @click="handlesetMainPropertyCheckboxClick($event, item)"
                                />
                            </td>

                            <!-- Nepremičnina -->
                            <td>
                                <select
                                    v-if="item.real_estate"
                                    v-model="item.real_estate.is_parcel"
                                    @change="onCustomTypeChange(item)"
                                    class="form-control re-type-dropdown"
                                    :disabled="item.geom && item.geom.geom && item.geom.geom.type == 'Polygon'"
                                > 
                                    <option :value="false">
                                        {{ $t("general:unit") }}
                                    </option>
                                    <option :value="true">
                                        {{ $t("general:parcel") }}
                                    </option>
                                </select>
                            </td>

                            <!-- Tip -->
                            <td>
                                <type-dropdown
                                    v-model="item.custom_type"
                                    :default-display-if-null="item.real_estate.re_type ? item.real_estate.re_type : _getDefaultTypeDisplay(item.real_estate.is_parcel)"
                                    :isParcel="item.real_estate.is_parcel"
                                    @reset="item.custom_type=null"
                                    v-on:input="$emit('set-session-default-name')"
                                />
                            </td>

                            <!-- Velikost -->
                            <td>
                                <number-input-field
                                    v-model="item.custom_size"
                                    v-on:input="$emit('size-changed')"
                                    :placeholder="_getRealEstateSizeDisplay(item)"
                                    inputFieldsAddon="m2"
                                    size="sm"
                                />
                            </td>

                            <!-- Leto izgradnje -->
                            <td>
                                <number-input-field
                                    v-if="item.real_estate && !item.real_estate.is_parcel"
                                    v-model="item.custom_data.year_built"
                                    placeholder="1950"
                                    :inputFieldsAddon="$t('general:year')"
                                    thousandsSeparator=""
                                    size="sm"
                                />
                            </td>
                        </tr>
                        <tr @click="addCustomRealEstateRow(true)">
                            <td class="td-selector" colspan="100%">
                                <i class="fa fa-plus text-muted m-l-10 m-r-10"></i>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="100%">
                                <add-rekeys-textarea
                                    @submit="addExistingParcels"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row m-b-10">
            <div class="col-lg-12 text-center">
                <button
                    class="btn btn-info"
                    v-on:click.prevent="$emit('close-me')"
                >
                    {{$t("general:save")}}
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <valuation-subject-wizard-map-manual
                    :real_estates="selected_subject.real_estates"
                    :highlighted_re_key="highlighted_re_key"
                    :editRealEstate="editingLocationOfRealEstate"
                    :defaultCenter="{
                        lat: 45.1000,
                        lng: 15.2000,
                    }"
                    :defaultZoom="7"
                    @change-pin-location="changeRealEstateLocation"
                    @add-real-estates="addExistingParcels"
                />
            </div>
        </div>
        <bootstrap3-modal
            :title="modalName"
            name="units-on-parcel-modal"
            id="units-on-parcel-modal"
        >
            <HRUnitsOnParcelTable
                v-if="unitsOnParcel"
                :rekey="unitsOnParcel.re_key"
                @unit-on-parcel-clicked="addRowFromUnitsOnParcel"
            >
                <button class="btn btn-info">
                    {{ $t("task_details:valuation:add_to") }}
                </button>
            </HRUnitsOnParcelTable>
        </bootstrap3-modal>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { Swal } from "@/components/utils/swal.alerts.js";
import ReOpenModal from "@/components/widgets/ReOpenModal.vue"
import HelpIcon from "@/components/widgets/HelpIcon";
import NumberInputField from "@/components/utils/inputs/NumberInputField.vue";
import BaseInputField from "@/components/utils/inputs/BaseInputField.vue";

import AddRekeysTextarea from "../components/AddRekeysTextarea.vue"
import ValuationSubjectWizardMapManual from "../components/map/ValuationSubjectWizardMapManual.vue"
import RealEstateTypeDropDown from "../components/RealEstateTypeDropDown.vue";
import VSUtils from "../components/utils.js"
import ApiService from "@/components/utils/api.service.js"

import UnitSizeEstimateIndicator from "@apps/real_estate/vue/components/UnitSizeEstimateIndicator.vue"
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import HRUnitsOnParcelTable from "@/components/hr-specific/HRUnitsOnParcelTable.vue";

export default {
    model: {
        prop: "subject",
        event: "change"
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        },
        session: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Loading, HelpIcon,
        ReOpenModal,
        AddRekeysTextarea,
        ValuationSubjectWizardMapManual,
        NumberInputField, BaseInputField,
        "type-dropdown": RealEstateTypeDropDown,
        UnitSizeEstimateIndicator,
        Bootstrap3Modal,
        HRUnitsOnParcelTable,
    },
    data: function () {
        return {
            VSUtils: VSUtils,
            highlighted_re_key: null,
            editingLocationOfRealEstate: null,  // real_estate or null if not editing
            parcelBuildings: {},
            unitsOnParcel: null,
        }
    },
    methods: {
        openModal: function(parcel) {
            this.unitsOnParcel = parcel;
            this.$modal.show("units-on-parcel-modal");
        },
        _getRealEstateSizeDisplay: function(real_estate){
            const display = VSUtils.getRealEstateSize(real_estate);
            return isNaN(display) ? null : display;
        },
        handlesetMainPropertyCheckboxClick: function(e, real_estate){
            if(e.target.checked){
                this.$emit("set-main-property", real_estate);
            } else {
                e.preventDefault();
            }
        },
        isEditableField: function(real_estate, field) {
            if (field === "re_key") {
                if (String(real_estate.id).startsWith("new_custom")) {
                    return true;
                }
            }
            return false;
        },
        setLocationEditing: function(real_estate) {
            this.editingLocationOfRealEstate = real_estate;
        },
        _setMainPropertyIfNotSelected: function() {
            const main = this.selected_subject.real_estates.filter(re => re.main_property)[0];
            if (main && !main.real_estate.is_parcel)  // main is already unit
                return;
            // find best candidate for main real estate
            let found = false;
            for (const real_estate of this.selected_subject.real_estates) {
                real_estate.main_property = false;
                if (!real_estate.real_estate.is_parcel) {
                    this.$emit("set-main-property", real_estate);
                    found = true;
                }
            }
            if (!found) {
                this.$emit("set-main-property", this.selected_subject.real_estates[0]);
            }
        },
        generateNewCustomID() {
            return "new_custom_" + Math.random().toString(16).slice(2);
        },
        addRowFromUnitsOnParcel(unit) {
            this.$modal.hide("units-on-parcel-modal");
            
            const unitWithSameReKey = this.selected_subject.real_estates.filter(re => re.re_key === unit.re_key);
            if (unitWithSameReKey.length > 0 && unitWithSameReKey[0].from_unit_on_parcel) {
                Swal({
                    icon: "warning",
                    title: "Unit already added",
                    text: "This unit is already added to the valuation.",
                    showCancelButton: false,
                    confirmButtonText: this.$t("general:ok"),
                });
            } else
                this.addCustomRealEstateRow(false, this.unitsOnParcel, unit);
        },
        async addCustomRealEstateRow(is_parcel, parent_parcel=null, unit_data={empty: true}) {
            let lat, lng = null;
            if (parent_parcel && parent_parcel.real_estate && parent_parcel.real_estate.gps_point)  // get center of parcel
                [lng, lat] = parent_parcel.real_estate.gps_point.coordinates
            if (parent_parcel && parent_parcel.custom_data && parent_parcel.custom_data.location)  // get center if custom data
                ({lng, lat} = parent_parcel.custom_data.location);

            let data = {
                id: this.generateNewCustomID(),
                session_id: this.session.id,
                re_key: unit_data.re_key || "",
                custom_type: this._getDefaultType(is_parcel),
                custom_size: unit_data.size || null,
                main_property: false,
                real_estate: {
                    re_key: unit_data.re_key || "",
                    address: unit_data.address || "",
                    is_parcel: is_parcel
                },
                custom_data: {
                    year_built: null,
                    location: {
                        lat: lat ? lat : null,
                        lng: lng ? lng : null,
                    },
                    building_on_parcels: parent_parcel !== null ? [parent_parcel.re_key] : [""],
                },
                from_unit_on_parcel: unit_data.empty !== true,
            }
            if (unit_data.re_key) {
                this._updateReForCustomParcelWhenAddingFromParcel(unit_data.re_key, parent_parcel.re_key);
            } else if (parent_parcel !== null) {
                data.re_key = await this._generateKey(parent_parcel.re_key);
            }
            this.subject.real_estates.push(data);
            this._setMainPropertyIfNotSelected();
        },
        addExistingParcels: function(re_keys) {
            if(!Array.isArray(re_keys)) {re_keys = re_keys.trim().split(/,|\n/) }
            this.$emit("add-re-to-subject", re_keys, this.session.id);
        },
        _updateReForCustomParcelWhenAddingFromParcel(re_key, parent_key) {
            const real_estate = this.subject.real_estates.filter(re => re.re_key === re_key)[0];
            const newUnitNumber = this._findHighestUnitNumberInValuationWith(parent_key) + 1;
            if (real_estate)
                real_estate.re_key = `${parent_key}-${newUnitNumber}`;
        },
        _findHighestUnitNumberInValuationWith(re_key) {
            const filtered = this.subject.real_estates.filter(re => re.re_key.startsWith(`${re_key}-`));
            const unitIDs = filtered.map(re => {
                const splitKey = re.re_key.split("-");
                return parseInt(splitKey[splitKey.length - 1]);
            });
            if (unitIDs.length === 0)
                return 0;
            return Math.max(...unitIDs);
        },
        async _generateKey(parent_key) {
            const currentHighestKey = this._findHighestUnitNumberInValuationWith(parent_key) + 1;

            if (parent_key in this.parcelBuildings) {
                this.parcelBuildings[parent_key]++
                const newKey = currentHighestKey > this.parcelBuildings[parent_key] ? currentHighestKey : this.parcelBuildings[parent_key];
                return `${parent_key}-${newKey}`;
            }
            const response = await ApiService.get(`/amas/real-estate/api/generate-key/${parent_key}`)
            const splitKey = response.data.split("-");
            let unit = parseInt(splitKey[splitKey.length - 1]);
            
            if (currentHighestKey > unit)
                unit = currentHighestKey;

            this.parcelBuildings[parent_key] = unit;
            return `${parent_key}-${unit}`;
        },
        _regenerateReKeys(deleted_re) {
            const parent_parcel = deleted_re.custom_data.building_on_parcels[0];
            if (this.isEditableField(deleted_re, 're_key')) {
                this.parcelBuildings[parent_parcel]--;
                this.subject.real_estates = this.subject.real_estates.map(real_estate => {
                    if (!real_estate.from_unit_on_parcel && real_estate.custom_data && real_estate.custom_data.building_on_parcels[0] === parent_parcel) {
                        const splitKey = real_estate.re_key.split("-");
                        const splitDeleted = deleted_re.re_key.split("-");
                        const keyID = parseInt(splitKey[splitKey.length - 1]);
                        const deletedID = parseInt(splitDeleted[splitDeleted.length - 1]);
                        
                        // check that re_key is not already taken
                        const newReKey = `${parent_parcel}-${keyID - 1}`
                        const reFromUnitOnParcel = this.subject.real_estates.filter(re => re.re_key === newReKey);
                        if (reFromUnitOnParcel.length > 0)
                            return real_estate;

                        if (keyID > deletedID) {
                            return {
                                ...real_estate,
                                re_key: newReKey
                            }    
                        }
                    }
                    return real_estate;
                });
            }
        },
        removeRealEstate(re) {
            this.subject.real_estates = this.subject.real_estates.filter(i => i.id !== re.id);
            this._setMainPropertyIfNotSelected();
            if (!re.real_estate.is_parcel)
                this._regenerateReKeys(re);
        },
        changeRealEstateLocation(location) {
            this.subject.real_estates
                .filter(i => i.id === this.editingLocationOfRealEstate.id)
                .map(i => {
                    if (i.custom_data === undefined)  // create custom_data object if it doesn't exist
                        i.custom_data = { location: null, }
                    return i.custom_data.location = location
                });
            this.editingLocationOfRealEstate = null;
        },
        _getDefaultType(isParcel) {
            return this.$store.state.realEstateTypeMap[isParcel ? "parcel" : "unit"][0].value.toString();
        },
        _getDefaultTypeDisplay(isParcel) {
            return VSUtils._mapRealEstateTypeIdToDisplay(this._getDefaultType(isParcel), isParcel);
        },
        onCustomTypeChange(item) {
            item.re_key = "";
            if (item.real_estate.is_parcel) {
                item.custom_data.building_on_parcels = [];
            }
            item.custom_type = null;
        },
        _clanupParcelBuildings() {
            // get all current "buildings on parcel"
            const usedParcels = new Set(this.subject.real_estates.map(real_estate => {
                if (real_estate.custom_data)
                    return real_estate.custom_data.building_on_parcels[0];
                return null;
            }));
            // remove all keys not currently seen
            for (const key in this.parcelBuildings) {
                if (!usedParcels.has(key)) {
                    delete this.parcelBuildings[key];
                }
            }
        },
        async onBuildingOnParcelsChange(item) {
            this._clanupParcelBuildings();
            item.id = this.generateNewCustomID();
            item.re_key = await this._generateKey(item.custom_data.building_on_parcels[0]);
        },
        isLocationForRealEstateSet(realEstate) {
            return (realEstate.geom && realEstate.geom.geom) || (realEstate.custom_data && realEstate.custom_data.location &&
                realEstate.custom_data.location.lat !== null && realEstate.custom_data.location.lon !== null)
        },
        removeNoneAndNullAddresses() {
            this.subject.real_estates = this.subject.real_estates.map(real_estate => {
                const address = real_estate.real_estate.address;
                if (address === null || address === "None")
                    return {
                        ...real_estate,
                        real_estate: {
                            ...real_estate.real_estate,
                            address: "",
                        }
                    }
                return real_estate;
            })
        },
    },
    computed: {
        selected_subject() {
            if (this.session === null)
                return this.subject;
            return {
                ...this.subject,
                real_estates: this.subject.real_estates.filter(re => re.session_id === this.session.id)
            }
        },
        modalName() {
            if (this.unitsOnParcel === null)
                return "";
            return `${this.$t('real_estate:unit:on_parcel')}: ${this.unitsOnParcel.re_key}`;
        },
    },
    mounted() {
        this.removeNoneAndNullAddresses();
    },
}
</script>
<style scoped>
.td-selector {
    cursor: pointer;
}
.td-selector:hover, .export-option:hover{
    background: #EFEFEF;
    
}
.map-marker-icon {
    font-size: 2rem;
    margin: 5px;
}
.re-type-dropdown {
    font-size: 12px;
    height: 30px;
}
</style>
