<template>
    <div>
        <div class="white-box">
            <div class="text-right">
                <button class="btn btn-sm btn-info btn-outline" @click="openCreateOrderModal">
                    Novo povpraševanje
                </button>
            </div>
        </div>

        <base-order-table
            :items="orders"
            :loading="loading_orders"
        >
            <template v-slot:actions="{ item }">
                <div :class="[
                    'button-holder',
                    {
                        'btn-group dropdown': checkOrderStatus(item, orderStatuses.ACCEPTED, orderStatuses.DRAFT, orderStatuses.REQUEST_FOR_QUOTE),
                    }]"
                >
                    <button
                        class="btn btn-sm btn-info btn-outline"
                        @click="openOrderDetailsModal(item)"
                    >
                        Odpri
                    </button>

                    <template v-if="checkOrderStatus(item, orderStatuses.ACCEPTED, orderStatuses.DRAFT, orderStatuses.REQUEST_FOR_QUOTE)">
                        <div class="btn btn-sm btn-info btn-outline dropdown-toggle"
                            type="button" data-toggle="dropdown"
                        >
                            <span class="caret"></span>
                        </div>
                        <ul class="dropdown-menu" role="menu">
                            <li v-if="checkOrderStatus(item, orderStatuses.ACCEPTED)">
                                <a @click="changeMode(item)" href="#">
                                    Ustvari naročilo
                                </a>
                            </li>
                            <li v-if="checkOrderStatus(item,
                                orderStatuses.DRAFT, orderStatuses.REQUEST_FOR_QUOTE)">
                                <a @click="cancelOrder(item)" href="#">
                                    Prekliči naročilo
                                </a>
                            </li>
                        </ul>
                    </template>
                </div>
            </template>
        </base-order-table>

        <!--
            START Modals
        -->
        <bootstrap3-modal
            title="Novo povpraševanje"
            name="createOrderModal"
            width="90%"
        >
            <CreateOrder
                @order-submitted="handleOrderSubmitted"
                @order-saved="handleOrderSubmitted"
            />
        </bootstrap3-modal>
        
        <bootstrap3-modal
            :title="getOrderTitle"
            name="orderDetailsModal"
            width="90%"
        >
            <OrderDetails
                v-if="selected_order"
                :orderId="selected_order.id"
                @order-submitted="handleOrderSubmitted"
                @order-saved="handleOrderSubmitted"
                @move-depository="handleMoveDepository"
            />
        </bootstrap3-modal>
        <!--
            END Modals
        -->
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import KEditable from "@/components/k-editable/KEditable";
import { Swal } from "@/components/utils/swal.alerts.js";

import BaseOrderTable from "../../components/BaseOrderTable.vue";
import CreateOrder from "./CreateOrder.vue";
import OrderDetails from "./OrderDetails.vue";

import orderstatusmixin from "../../components/mixins/orderstatusmixin.js";
import { getOrderTitle } from "../../components/utils.js";

export default {
    mixins: [ orderstatusmixin ],
    components: {
        BaseOrderTable,
        CreateOrder,
        OrderDetails,
        "bootstrap3-modal": Bootstrap3Modal,
        "k-editable": KEditable,
    },
    data() {
        return {
            orders: [],
            loading_orders: false,
            selected_order: null,
        }
    },
    methods: {
        getOrders: function() {
            this.loading_orders = true;
            return ApiService
                .get("/streamline/api/order/list", {
                    params: { "process": "procurement" }
                })
                .then(response => {
                    this.orders = response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju naročil",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading_orders = false;
                })
        },
        changeMode: function(order){
            return ApiService
                .post(`/streamline/api/order/${order.id}/move-depository/`)
                .then(response => {
                    Swal({
                        title: "",
                        text: "Premaknjeno v naročila",
                        icon: "success",
                    });
                    // this.$emit("move-depository");
                    this.getOrders();
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri premiku v naročila",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
        },
        cancelOrder: function(order) {
            Swal({
                title: "",
                text: "Naročilo bo preklicano, želite nadaljevati?",
                icon: "warning",
                confirmButtonText: "Da",
                cancelButtonText: "Ne",
                showCancelButton: true,
            }).then(val => {
                if (val.isConfirmed) {
                    return ApiService
                        .post(`/streamline/api/order/${order.id}/cancel/`)
                        .then(response => {
                            Swal({
                                title: "",
                                text: "Naročilo je bilo preklicano",
                                icon: "success",
                            });
                            this.getOrders();
                        })
                        .catch(error => {
                            Swal({
                                title: "Napaka pri preklicu naročila",
                                text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                                icon: "error",
                            })
                            throw error;
                        })
                }
            })
        },
        // Modals
        handleOrderSubmitted: function(order) {
            if (this.checkOrderStatus(order, this.orderStatuses.DRAFT)) {
                // DRAFT - don't close modal
            } else {
                this.$modal.hide("createOrderModal");
            }
            this.getOrders();
        },
        handleMoveDepository: function() {
            this.getOrders();
            this.$modal.hide("orderDetailsModal");
        },
        openCreateOrderModal: function() {
            this.$modal.show("createOrderModal");
        },
        openOrderDetailsModal: function(order) {
            this.selected_order = order;
            this.$modal.show("orderDetailsModal");
        },
    },
    mounted() {
        this.getOrders();
    },
    computed: {
        getOrderTitle() {
            if (!this.selected_order)
                return "Podrobnosti";
            return "Povpraševanje " + getOrderTitle(this.selected_order);
        },
    },
}
</script>
