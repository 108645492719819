import { render, staticRenderFns } from "./OrderInternalCommentSection.vue?vue&type=template&id=7ebcca94&scoped=true&"
import script from "./OrderInternalCommentSection.vue?vue&type=script&lang=js&"
export * from "./OrderInternalCommentSection.vue?vue&type=script&lang=js&"
import style0 from "./OrderInternalCommentSection.vue?vue&type=style&index=0&id=7ebcca94&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../vue/vue2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ebcca94",
  null
  
)

export default component.exports