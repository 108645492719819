import { render, staticRenderFns } from "./TransactionDetails.vue?vue&type=template&id=638f5188&scoped=true&"
import script from "./TransactionDetails.vue?vue&type=script&lang=js&"
export * from "./TransactionDetails.vue?vue&type=script&lang=js&"
import style0 from "./TransactionDetails.vue?vue&type=style&index=0&id=638f5188&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../vue/vue2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638f5188",
  null
  
)

export default component.exports