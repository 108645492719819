import { render, staticRenderFns } from "./ManageRealEstatesManual.vue?vue&type=template&id=0936f3a2&scoped=true&"
import script from "./ManageRealEstatesManual.vue?vue&type=script&lang=js&"
export * from "./ManageRealEstatesManual.vue?vue&type=script&lang=js&"
import style0 from "./ManageRealEstatesManual.vue?vue&type=style&index=0&id=0936f3a2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../vue/vue2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0936f3a2",
  null
  
)

export default component.exports