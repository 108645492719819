import Vue from 'vue'

import i18n from "./i18n.js"

// VModal
import vmodal from 'vue-js-modal'
Vue.use(vmodal)

// VTooltip
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import {urlGeneratorMixin} from '@/components/mixins/urlgenerator.js'
Vue.mixin(urlGeneratorMixin)

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAMtKKKL0-8n8fDiHgYQOfJXmJ0mnw9mVE',
        libraries: 'places'
    },
    installComponents: true
})

import  '@/components/utils/filters.js'

import ApiService from '@/components/utils/api.service.js'
ApiService.init();

import Globals from "./components/utils/globals.js"

import DataSource from '@/components/widgets/DataSource.vue';
Vue.component("data-source", DataSource);

// Formulate
import './vue-formulate.js'

// Vue-loading-overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component("loading", VueLoading);

// Opentok
const OT = require("@opentok/client");
Vue.prototype.$OT = OT;

// import components here
import AdvertSearchForm from '@apps/analysis/advertisment/vue/AdvertSearchForm.vue'
import AdvertSearchResultTable from '@apps/analysis/advertisment/vue/ResultTable.vue'

// AdministrativeActs
import AdministrativeActs from '@apps/analysis/analysis/vue/administrative_acts/AdministrativeActs'

// PublicRoadAccess
import PublicRoadAccess from '@apps/analysis/analysis/vue/public_road_access/PublicRoadAccess'

// ESGCalculator
import ESGCalculator from '@apps/analysis/analysis/vue/esg_calculator/ESGCalculator'

// AdjustmentEditor
import AdjustmentEditor from '@apps/analysis/avm/comparison/vue/adjustments/AdjustmentsEditor'

// AMASComparableFinder
import AMASComparableFinder from '@apps/analysis/avm/comparison/vue/comparable_finder/AMASComparableFinder'

// LegalStatus
import LegalStatusApp from '@apps/analysis/legal/vue/LegalStatusApp'

// EnergyReport
import EnergyReportApp from '@apps/analysis/energy_report/vue/EnergyReportApp'

// ValuationSubjectWizard
import ValuationSubjectWizardCreate from '@apps/analysis/project/vue/valuation_subject_wizard/ValuationSubjectWizardCreate'
import ValuationSubjectWizardUpdateProject from '@apps/analysis/project/vue/valuation_subject_wizard/ValuationSubjectWizardUpdateProject'

// MultipleReportPicker
import MultipleReportPicker from '@apps/analysis/project/vue/multiple_report_picker/MultipleReportPicker'

// PropertyIQ
import PropertyIQReport from '@apps/propertyiq/vue/report/PropertyIQReport'
import PropertyIQStartSession from '@apps/propertyiq/vue/report/PropertyIQStartSession'

import BasketList from '@apps/market/market_search/vue/BasketList.vue'
import BasketAddPopover from '@apps/market/market_search/vue/BasketAddPopover.vue'
import MarketSearchResultsMap from '@apps/market/market_search/vue/search/MarketSearchResultsMap.vue'
import MarketSearchResultsTable from '@apps/market/market_search/vue/search/MarketSearchResultsTable.vue'
import MarketSearchResultsFilter from '@apps/market/market_search/vue/filters/MarketSearchResultsFilter.vue'

import KEditable from './components/k-editable/KEditable.vue'
import KSimpleLeafletMap from '@/components/k-leaflet-map/KSimpleLeafletMap'
import KWMSLeafletMap from '@/components/k-leaflet-map/KWMSLeafletMap'
import KArcGisLeafletMap from '@/components/k-leaflet-map/KArcGisLeafletMap'

// Analytics
import AnalyticsDashboard from '@apps/analytics/vue/dashboard/AnalyticsDashboard.vue'
import AnalyticsDashboardRegion from '@apps/analytics/vue/dashboard/AnalyticsDashboardRegion.vue'
import NewsFeed from '@apps/analytics/vue/NewsFeed.vue'

// Google maps components
import KGoogleMap from '@/components/k-google-map/KGoogleMap'
import KGoogleMapSaveImage from '@/components/k-google-map/KGoogleMapSaveImage'
import KGoogleStreetview from '@/components/k-google-map/KGoogleStreetview'
import KGoogleStreetviewSaveImage from '@/components/k-google-map/KGoogleStreetviewSaveImage'

import Bootstrap3Modal from '@/components/utils/Bootstrap3Modal.vue'
import BasicSlider from '@/components/sliders/BasicSlider.vue'

import GooglePlacesResults from '@/components/google-places/GooglePlacesResults.vue'

// SupportModal component
import SupportModal from '@/components/support-modal/SupportModal.vue'

// Property transaction history
import PropertyTransactionHistory from '@/components/property-transaction-history/PropertyTransactionHistory.vue'
import PropertiesTransactionHistory from '@/components/property-transaction-history/PropertiesTransactionHistory.vue'

// Streamline
import BuyerProcurement from '@apps/streamline/vue/buyer/procurement/BuyerProcurement.vue';
import BuyerDepository from '@apps/streamline/vue/buyer/depository/BuyerDepository.vue';
import SupplierProcurement from '@apps/streamline/vue/supplier/procurement/SupplierProcurement.vue';
import SupplierDepository from '@apps/streamline/vue/supplier/depository/SupplierDepository.vue';

// Size estimator
import UnitSizeEstimateIndicatorWrapper from '@apps/real_estate/vue/components/UnitSizeEstimateIndicatorWrapper.vue';

// VirtualVisitCall
import VirtualVisitCall from "@apps/virtual_visit/vue/VirtualVisitCall.vue"

// HR Specific
import HRUnitsOnParcelTable from '@/components/hr-specific/HRUnitsOnParcelTable.vue'
import HRSearchMap from '@/components/hr-specific/searchmap/HRSearchMap.vue'
import HRFloodRiskMap from '@/components/hr-specific/HRFloodRiskMap.vue'

// export components for this library
export default {
    i18n,
    ApiService,
    Globals,

    AdvertSearchForm,
    AdvertSearchResultTable,
    BasketList,
    BasketAddPopover,
    MarketSearchResultsMap,
    MarketSearchResultsTable,
    MarketSearchResultsFilter,
    KEditable,
    KSimpleLeafletMap,
    KWMSLeafletMap,
    KArcGisLeafletMap,

    // Google maps components
    KGoogleMap, KGoogleMapSaveImage, KGoogleStreetview, KGoogleStreetviewSaveImage,

    Bootstrap3Modal,
    GooglePlacesResults,
    BasicSlider,
    AdministrativeActs,
    PublicRoadAccess,
    ESGCalculator,
    LegalStatusApp,
    EnergyReportApp,
    SupportModal,
    AdjustmentEditor,

    // PropertyIQ
    PropertyIQReport,
    PropertyIQStartSession,
    PropertyTransactionHistory,
    PropertiesTransactionHistory,

    // Analytics
    AnalyticsDashboard,
    AnalyticsDashboardRegion,
    NewsFeed,

    // ValuationSubjectWizard
    ValuationSubjectWizardCreate,
    ValuationSubjectWizardUpdateProject,

    // MultipleReportPicker
    MultipleReportPicker,

    // AMASComparableFinder,
    AMASComparableFinder,

    // Streamline
    BuyerProcurement,
    BuyerDepository,
    SupplierProcurement,
    SupplierDepository,

    // Size estimator
    UnitSizeEstimateIndicatorWrapper,

    // VirtualVisitCall
    VirtualVisitCall,
    
    // HR Specific
    HRUnitsOnParcelTable,
    HRSearchMap,
    HRFloodRiskMap,

};