<template>
    <div class="row">
        <div class="col-md-12">
            <div class="panel panel-info">
                <div class="panel-heading" data-perform="panel-collapse" v-if="title">
                    {{ title }}

                    <div class="panel-action">
                        <slot name="actions"></slot>
                        <a href="#" data-perform="panel-collapse"><i class="ti-minus"></i></a>
                    </div>
                </div>
                <div class="panel-wrapper collapse in">
                    <div class="panel-body">
                        <div>
                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormPanelSection',
    props: {
        title: {
            type: String,
            required: false
        },
    }
}
</script>
