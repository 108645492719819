<template>
    <div>
        <BaseInput
            :prependIconClass="prependIconClass"
            :appendIconClass="appendIconClass"
            :prependIcon="prependIcon"
            :appendIcon="appendIcon"
            :errorMessage="customErrorMessage"
            :showErrorMessage="customShowErrorMessage"
            @prepend-icon-click="$emit('prepend-icon-click')"
            @append-icon-click="$emit('append-icon-click')"
        >
            <input
                v-model="context.model"
                v-bind="context.attributes"
                :data-type="context.type"
                :type="context.type"

                :min="min"
                :max="max"
                @blur="updateErrors"
                @focus="clearErrors"
            />
        </BaseInput>
    </div>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
import baseinputmixin from "../mixins/baseinputmixin";
import BaseInput from "./BaseInput.vue";

export default {
    name: "TextInput",
    mixins: [ FormulateInputMixin, baseinputmixin ],
    components: {
        BaseInput,
    },
    props: {
        pattern: {
            type: String,
            default: null,
        },
    },
    methods: {
        isValid() {
            if (!this.context.model || !this.pattern)  // not empty
                return true;
            const regex = new RegExp(this.pattern);
            return regex.test(this.context.model);
        },
    },
}
</script>
