<template>
    <div>
        <div v-if="is_loading" >
            <span class="circle circle-sm bg-info" data-toggle="tooltip" :data-original-title="$t('task_details:status:fetching_data')">
                <i class="fa fa-spinner fa-spin"></i>
            </span>
        </div>

        <div v-else-if="!task_status || task_status.status_code==='SKIPPED'">
            <span class="circle circle-sm bg-light" data-toggle="tooltip" :data-original-title="$t('task_details:status:wansnt_done')">
                <i class="icon-ban"></i>
            </span>
        </div>

        <div v-else-if="task_status.status_code==='FAILED'">
            <span class="circle circle-sm bg-danger" data-toggle="tooltip" :data-original-title="task_status.msg">
                /
            </span>
        </div>

        <div v-else v-html="icon"></div>
    </div>
</template>


<script>
export default {
    props: {
        session_id: {
            type: [Number, String],
            required: true
        },
        task_name: {
            type: String,
            required: true
        },
        finishedHook: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            loading: false,
            icon: "",
            iter: 0,
        }
    },
    methods: {
        refreshSessionTaskStatus: function() {
            if( this.iter > 20 ) {
                this.$store.commit("updateTaskStatusItem", {
                    session_id: this.session_id,
                    task_name: this.task_name,
                    task_status: {
                        status_code: "FAILED",
                        msg: "Napaka na strežniku",
                    }
                });
                this.loading = false;
                return
            }
            this.iter += 1;
            this.$store.dispatch("retrieveSessionTaskStatusItem", {
                session_id: this.session_id,
                task_name: this.task_name,
            });
        },
        handleTaskStatus: function() {
            if ( !this.task_status ) { // Wasn't started
                this.loading = false;
            } else if( this.task_status.status_code === "STARTED" ) {
                this.loading = true;
                setTimeout(this.refreshSessionTaskStatus, 3000);
            } else if( this.task_status.status_code === "FAILED" ) {
                this.loading = false;
            } else if( this.task_status.status_code === "FINISHED" ) {
                this.loading = true;
                this.finishedHook(this.task_status)
                    .then((icon) => {
                        this.icon = icon;
                        this.loading = false;
                    });
            }
        }
    },
    computed: {
        session_task_status: function() {
            return this.$store.getters.getSingleSessionTaskStatus(this.session_id);
        },
        task_status: function() {
            return this.session_task_status ? this.session_task_status[this.task_name] : null;
        },
        is_loading: function() {
            return this.loading || this.session_task_status == "loading" || this.task_status == "loading";
        },
    },
    watch: {
        task_status: function (_val) {
            this.handleTaskStatus();
        },
    },
}
</script>