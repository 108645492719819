/*
Mixin used for extending the BaseTable component
*/

import orderstatusmixin from "./orderstatusmixin";

export default {
    mixins: [ orderstatusmixin ],
    emits: [ "item-row-click" ],
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * Array of objects describing the columns.
         * Each object may have the following properties:
         * - field: the name of the field in the item object
         * - label: the label of the column
         * - sortable?: whether the column is sortable (default: true)
         * - sort_conversion?: function that gets called on the value when sorting (default: null)
         * - hidden?: whether the column is hidden (default: false)
         * - slot_name?: the name of the slot to use for the column (default: null)
         * - filters?: the filters to use for the column (default: null)
         */
        columns: {
            type: Array,
            default: () => [],
        },
        getRowClass: {
            type: Function,
            default: () => null,
        },
    },
    computed: {
        columns_joined() {
            // Extends base_columns with custom columns if available
            if (!this.base_columns)
                return this.columns;
            return this.base_columns.map(base_column => {
                const custom_column = this.columns.find(column => column.field === base_column.field);
                if (custom_column)
                    return { ...base_column, ...custom_column};
                return base_column;
            }).filter(col => !col.hidden);
        },
    },
    methods: {
        uniqueKey(text, ...indexes) {
            return `${text}_${indexes.join("_")}`;
        },
        getSortConversion(key) {
            const definition = this.columns.find(col => col.field == key)
            if (definition && definition.sort_conversion)
                return definition.sort_conversion;
            return x => x[key];
        },
        getStatusBadgeClass(status) {
            const badgeClasses = {
                [this.orderStatuses.DRAFT]: "badge-inverse",
                [this.orderStatuses.REQUEST_FOR_QUOTE]: "badge-success",
                [this.orderStatuses.COMPLETED]: "badge-success",
                [this.orderStatuses.CANCELED]: "badge-danger",
                default: "badge-info"
            };
            return `label badge ${badgeClasses[status] || badgeClasses.default}`;
        }
    }
}
