<template>
    <div>
        <base-task-status
            :session_id="session.id"
            task_name="cost_method"
            :finishedHook="handleTaskStatusFinished"
        />
    </div>
</template>


<script>
import ApiService from "@/components/utils/api.service.js"
import Utils from "@/components/utils/utils.js"
import BaseTaskStatus from "./BaseTaskStatus.vue"

export default {
    components: {
        BaseTaskStatus
    },
    props: {
        session: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        disabledTooltip() {
            let tooltip = this.$t("comparable:enekretnine:tooltip");
            return `
                <a>
                    <span
                        class="disabled circle circle-sm bg-info"
                        data-toggle="tooltip" data-original-title="${tooltip}"
                    >
                    ?
                    </span>
                </a>
            `
        }
    },
    methods: {
        handleTaskStatusFinished: function(task_status) {
            this.$store.dispatch("retrieveSessionAppraisalValue", this.session.id);
            return ApiService
                .get(`/amas/analysis/api/cost-method/${this.session.id}`)
                .then(response => {
                    let data = response.data;
                    // let parcel_value = data.comparable_parcels_value;
                    let indication_value = data.indication_value;
                    
                    let icon, tooltip, bg;
                    if (!this.disabled) {
                        let url = `/amas/analysis/${this.session.id}/cost-method/calculation/comparable-parcels`;
                        if (indication_value) {
                            icon = "fa fa-check";
                            bg = "success";
                            tooltip = `Cost method created, indication value: ${Utils.formatCurrency(indication_value)}`
                        } else {
                            icon = "fa fa-close";
                            bg = "light";
                            tooltip = `Error`
                        }
                        icon = `
                                <a href="${url}">
                                    <span
                                        class="circle circle-sm bg-${bg}"
                                        data-toggle="tooltip" data-original-title="${tooltip}"
                                    >
                                        <i class="${icon}"></i>
                                    </span>
                                </a>
                            `
                    } else {
                        icon = this.disabledTooltip();
                    }
                    return icon;
                })
        }
    },
}
</script>