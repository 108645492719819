export function formatDate(dateString, includeTime = false) {
    if (!dateString)
        return "";
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    if (includeTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
    }
    return new Intl.DateTimeFormat('sl-SI', options).format(date).replace(',', '');
}

export function getOrderTitle(order) {
    let title = "#" + order.id;
    if (order.title) {
        title += " - " + order.title;
    }
    return title;
}

export default {
    formatDate,
    getOrderTitle,
}
