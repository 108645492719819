<template>
    <div class="row">
        <div class="col-md-12">
            <h3>{{ $t("property_iq:tabs:legal") }}</h3>
            <legal-status-app
                :session_id="$store.state.sessionId"
            />
        </div>
    </div>
</template>

<script>
import LegalStatusApp from "@apps/analysis/legal/vue/LegalStatusApp.vue"

export default {
    name: "LegalTab",
    components: {
        LegalStatusApp
    },
}
</script>