<template>
    <input
        v-model="context.model"
        v-bind="context.attributes"
        :data-type="context.type"
        :type="context.type"
    />
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";

export default {
    name: "CheckboxInput",
    mixins: [ FormulateInputMixin ],
}
</script>

<style>
.formulate-input-wrapper input.checkbox {
    display: inline;
}
</style>
