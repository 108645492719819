<template>
    <div style="min-height:50px;" class="vld-parent">
        
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div 
            v-if="show_zk_credentials_warning && currentUser && !currentUser.profile.are_zk_credentials_set"
        >
            <div class="alert alert-danger">
              {{ $t('legal:info')}}

            </div>
            <user-profile-zk
                v-model="currentUser"
            >
            </user-profile-zk>
        </div>

        <div
            v-for="(msg, idx) in errorMessages"
            class="alert alert-danger"
            :key="'errormsg-'+idx"
        >
            {{msg}}
        </div>

        <div
            v-if="create_success"
            class="alert alert-success"
        >
          {{ $t('legal:success')}}
        </div>

        <button class="btn btn-default"
            @click="getSessionLegalStatuses('force_recreate')"
        >
        {{ $t('legal:rebuild')}}
		</button>

        <div 
            v-for="(ls, idx) in legal_statuses"
            :key="'ls-'+idx"
        >
            <legal-status-details
                v-model="legal_statuses[idx]"
                :current_user="currentUser ? currentUser : null"
                :manual_search_link="manual_search_link"
                @delete-legal-status="getSessionLegalStatuses('delete', legal_statuses[idx].id)"
            />
        </div>
        <file-upload
            class="m-t-10"
            @file-selected="onFileUpload"
            :allowDragAndDrop="true"
            :defaultStyling="true"
        />
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import LegalStatusDetails from "./components/LegalStatusDetails.vue"
import UserProfileZk from "@apps/user/userprofile/vue/components/UserProfileZk.vue"
import FileUpload from "@/components/utils/FileUpload.vue"

import ApiService from "@/components/utils/api.service.js"

export default {
    components: {
        Loading,
        LegalStatusDetails,
        UserProfileZk,
        FileUpload,
    },
    props: {
        session_id: {
            type: Number,
            required: true
        },
        show_zk_credentials_warning: {
          type: Boolean,
          default: true
        },
        manual_search_link: {
            type: String,
            default: "https://evlozisce.sodisce.si/esodstvo/index.html",
        },
    },
    data() {
        return {
            currentUser: null,
            loading: false,
            legal_statuses: [],
            create_success: null,
            errorMessages: [],
        }
    },
    methods: {
        addRetrievalErrorMessages: function(message) {
            this.errorMessages.push(message);
        },
        getSessionLegalStatuses: function(action="create", id=null) {
            this.loading = true;
            ApiService
                .get(`/amas/analysis/analysis/api/${this.session_id}/legal-status`, {
                        params: {
                            action,
                            id
                        }
                    })
                .then(response => {
                    this.legal_statuses = response.data.session_legal_statuses;
                    this.create_success = response.data.create_success && action==="force_recreate";
                    response.data.status_messages.forEach(message => {
                        this.addRetrievalErrorMessages(message);
                    });
                })
                .catch(error => {
                    this.errorMessages.push(this.$t("legal:fetch:error"));
                    this.loading = false;
                    throw error;
                })
                .then(() => {
                    this.loading = false;
                });
        },
        getCurrentUser: function() {
            return ApiService
                .get("/api/v1/current-user")
                .then(response => {
                    this.currentUser = response.data;
                })
        },
        onFileUpload(file) {
            this.loading = true;
            const formData = new FormData();
            formData.append("file", file[0]);
            ApiService
                .post(`/amas/analysis/analysis/api/${this.session_id}/legal-status`, formData)
                .then(() => {
                    this.getSessionLegalStatuses("create");
                })
                .catch(error => {
                    if (error.response.status) {
                        this.errorMessages.push(this.$t("legal:upload_parse_failed"));
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        const promise = this.getCurrentUser();
        promise.then(() => {
            if (this.currentUser.profile.are_zk_credentials_set || !this.show_zk_credentials_warning){
                this.getSessionLegalStatuses("create");
            }
        });
     }
}
</script>
