<template>
    <tbody>
        <tr class="section-title-row">
            <td
                :colspan="subject.session_list.length + 1"
            >
            {{ $t("task_details:export_reports") }}
            </td>
        </tr>
        <tr>
            <td></td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'vs-recap-appraisal-value-td-session-'+session_idx"
                class="text-center"
                style="vertical-align: middle;"
            >
                <button class="btn btn-info" @click="openModal(session_idx)">
                    {{ $t("general:export") }} <i class="fa fa-download"></i>
                </button>

                <bootstrap3-modal
                    title="Izberite poročila, ki jih želite izvoziti"
                    :name="'report-export-modal-' + _uid + session_idx"
                    width="60%"
                    class="vs--export-report-modal"
                >
                    <multiple-report-picker
                        :analysis="session.id"
                    />
                </bootstrap3-modal>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import MultipleReportPicker from '@apps/analysis/project/vue/multiple_report_picker/MultipleReportPicker'

export default {
    props: {
        subject: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        MultipleReportPicker,
        "bootstrap3-modal": Bootstrap3Modal,
    },
    methods: {
        openModal(no) {
            this.$modal.show('report-export-modal-' + this._uid + no);
        }
    }
}
</script>

<style>
.vs--export-report-modal .vm--modal {
    overflow: unset!important; /* To show when multiselect options are opened */
}
</style>