<template>
    <tbody>
        <tr class="section-title-row">
            <td
                :colspan="subject.session_list.length + 1"
            >
                {{$t("task_details:scope")}}
            </td>
        </tr>
        <tr v-for="(scope_item, idx) in getScopeData" :key="idx" class="valuation-scope-task">
            <td>
                {{scope_item.display_name}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'scope-'+idx+'-session-'+session_idx"
                class="text-center"
                style="vertical-align: middle;"
                v-tooltip="scope_item.tooltip"
            >
                <input type="checkbox" 
                    v-if="editable"
                    v-model="session.scope[scope_item.name]"
                    :disabled="scope_item.disabled"
                />
                <div v-else>
                    <i 
                        class="fa"
                        :class="{
                            'fa fa-check-square-o': session.scope[scope_item.name],
                            'fa-square-o': !session.scope[scope_item.name],
                        }"
                    >
                    </i>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import countrymixin from '@/mixins/countrymixin';

export default {
    mixins: [ countrymixin ],
    model: {
        prop: 'subject',
        event: 'change'
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        permissions: {
            type: Object,
            required: true,
        },
    },
    methods: {
        countryScopeAvailable: function(task) {
            /*
            Returns true if scope is available for current country
            */
            return this.isCountryPluginLoaded && this.getCountryPlugin("AMAS_PROJECT_SCOPE")[task].available;
        },
    },
    computed: {
        getScopeData: function() {
            /*
            Returns scope data for easier loop display
            */
            const scope = [];
            if (this.countryScopeAvailable("legal_status") && this.permissions.legalStatus)
                scope.push({
                    display_name: this.$t("task_details:scope:legal_status"),
                    name: "legal_status",
                });
            if (this.countryScopeAvailable("administrative_acts") && this.permissions.administrativeActs)
                scope.push({
                    display_name: this.$t("task_details:scope:administrative_acts"),
                    name: "administrative_acts",
                });
            if (this.countryScopeAvailable("public_road_access") && this.permissions.publicRoadAccess)
                scope.push({
                    display_name: this.$t("task_details:scope:public_road_access"),
                    name: "public_road_access",
                });
            if (this.countryScopeAvailable("comparable_transactions") && this.permissions.comparableTransactions)
                scope.push({
                    display_name: this.$t("task_details:scope:comparable_transactions"),
                    name: "comparable_transactions",
                    disabled: !this.permissions.accessExternalTransactions,
                    tooltip: !this.permissions.accessExternalTransactions ? this.$t("comparable:enekretnine:info") : null,
                });
            if (this.countryScopeAvailable("cost_method") && this.permissions.costMethod)
                scope.push({
                    display_name: this.$t("task_details:scope:cost_method"),
                    name: "cost_method",
                    disabled: !this.permissions.accessExternalTransactions,
                    tooltip: !this.permissions.accessExternalTransactions ? this.$t("comparable:enekretnine:info") : null,
                });
            if (this.countryScopeAvailable("comparable_adverts") && this.permissions.comparableAdverts)
                scope.push({
                    display_name: this.$t("task_details:scope:comparable_adverts"),
                    name: "comparable_adverts",
                });
            if (this.countryScopeAvailable("income_based_approach") && this.permissions.incomeBasedApproach)
                scope.push({
                    display_name: this.$t("task_details:scope:income_based_approach"),
                    name: "income_based_approach",
                });
            if (this.countryScopeAvailable("esg_report") && this.permissions.esgReport)
                scope.push({
                    display_name: "ESG",
                    name: "esg_report",
                });
            if (this.countryScopeAvailable("region_analytics_dashboard") && this.permissions.marketAnalysis)
                scope.push({
                    display_name: this.$t("task_details:scope:region_analytics_dashboard"),
                    name: "region_analytics_dashboard",
                });
            if (this.countryScopeAvailable("document_cadastral_plan") && this.permissions.documentCadastralPlan)
                scope.push({
                    display_name: this.$t("task_details:scope:cadastral_plan"),
                    name: "document_cadastral_plan",
                });
            if (this.countryScopeAvailable("document_possession_sheet") && this.permissions.documentPossessionSheet)
                scope.push({
                    display_name: this.$t("task_details:scope:possession_sheet"),
                    name: "document_possession_sheet",
                });
            return scope;
        },
    },
}
</script>
