<template>
    <order
        :orderId="orderId"
        :readOnly="true"
        :showSubmitOfferOptions="true"
        :showProcurementForTeamSelection="false"
        :showOffers="true"
        :orderItemDetails_forceRealEstateTableReadOnly="true"
    />
</template>

<script>
import Order from "../../components/Order.vue"

export default {
    components: { Order },
    props: {
        orderId: {
            type: [ String, Number ],
            required: true
        },
    },
}
</script>
