<template>
    <div>
        <BaseInput
            :prependIconClass="prependIconClass"
            :appendIconClass="appendIconClass"
            :prependIcon="prependIcon"
            :appendIcon="appendIcon"
            :errorMessage="customErrorMessage"
            :showErrorMessage="customShowErrorMessage"
            @prepend-icon-click="$emit('prepend-icon-click')"
            @append-icon-click="$emit('append-icon-click')"
        >
            <input
                v-bind="attributes"
                type="file"

                :accept="accept"
                @change="$emit('handle-upload', $event.target.files[0])"
            />
        </BaseInput>
    </div>
</template>

<script>
import FormulateInputMixin from "@braid/vue-formulate/src/FormulateInputMixin";
import baseinputmixin from "../mixins/baseinputmixin";
import BaseInput from "./BaseInput.vue";

export default {
    name: "FileInput",
    emits: ["handle-upload"],
    mixins: [ FormulateInputMixin, baseinputmixin ],
    components: {
        BaseInput,
    },
    props: {
        pattern: {
            type: String,
            default: null,
        },
        accept: {
            type: String,
            default: null,
        },
        uploader: {
            type: Function,
            default: () => {},
        }
    },
}
</script>
