<template>
    <tbody>
        <tr class="section-title-row">
            <td
                :colspan="subject.session_list.length + 1"
            >
                {{$t("general:summary")}}
            </td>
        </tr>
        <tr>
            <td>
                {{$t("task_details:recap:estimated_value")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'vs-recap-appraisal-value-td-session-'+session_idx"
                class="text-center"
                style="vertical-align: middle;"
            >
                <div 
                    class="text-primary text-semibold" style="font-size: 16px;"
                >
                    <div v-if="isLoading(session.id)">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                    <div v-else-if="getAppraisalValue(session.id)">
                        {{getAppraisalValue(session.id).appraised_value||"/"|formatCurrency}}
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                {{$t("task_details:recap:estimated_value_per_m2")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'vs-recap-appraisal-value-m2-td-session-'+session_idx"
                class="text-center"
                style="vertical-align: middle;"
            >
                <div 
                    class="text-info text-semibold" style="font-size: 16px;"
                >
                    <div v-if="isLoading(session.id)">
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                    <div v-else-if="getAppraisalValue(session.id)">
                        {{getAppraisalValue(session.id).appraised_value_m2||"/"|formatCurrencym2}}
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
export default {
    model: {
        prop: 'subject',
        event: 'change' 
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        getTaskStatus: function(session_id) {
            const task_status = this.$store.getters.getSingleSessionTaskStatus(session_id);
            return task_status || null;
        },
        getAppraisalValue: function(session_id) {
            return this.$store.getters.getSessionAppraisalValue(session_id);
        },
        isLoading: function(session_id) {
            // check that all value related task statuses are finished
            const valueRelatedTaskStatuses = ["comparable_transactions_sale", "cost_method"]; // extend when needed
            const allTaskStatuses = this.getTaskStatus(session_id);
            if (!allTaskStatuses) {
                return true;
            }
            for (const status of valueRelatedTaskStatuses) {
                const currentTaskStatus = allTaskStatuses[status];
                if (currentTaskStatus && (currentTaskStatus === "loading" || currentTaskStatus.status_code === "STARTED")) {
                    return true;
                }
            }

            // check that the appraised_value has a value
            const appraised_value = this.getAppraisalValue(session_id);
            if (appraised_value && (appraised_value.appraised_value || appraised_value.appraised_value_m2)) {
                return false;
            }
            return true;
        },
    },
    mounted() {
        // retrieve session appraisal values at the start
        for (const session of this.subject.session_list) {
            this.$store.dispatch("retrieveSessionAppraisalValue", session.id)
        }
    }
}
</script>
