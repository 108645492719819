export default {
    created() {
        this.orderStatuses = {
            DRAFT: 10,
            REQUEST_FOR_QUOTE: 11,
            IN_SELECTION: 12,
            ACCEPTED: 13,
            OPEN: 20,
            IN_PROGRESS: 21,
            REJECTED: 22,
            COMPLETED: 23,
            FOR_DISPATCH: 24,
            CANCELED: 90,
        }
    },
    methods: {
        checkOrderStatus(order, ...statuses) {
            return [...statuses].includes(order.status);
        }
    }
}
