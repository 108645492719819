<template>
    <div>
        <HistogramSlider
            :width="null"
            :from="from"
            :to="to"
            :min="getMin"
            :max="getMax"
            :step="step"
            :drag-interval="true"
            :input_fields="true"
            :input_fields_addon="input_fields_addon"
            :display_histogram="false"
            :max_postfix="max_postfix"
            :postfix="postfix"
            :histSliderGap="36"
            @change="$emit('change', $event)"
            ref="histogramslider">
        </HistogramSlider>
    </div>
</template>

<script>
import HistogramSlider from '@/components/histogram-slider/HistogramSlider.vue'

export default {
    name: 'SliderComponent',
    components: {
        HistogramSlider
    },
    props:  {
        val: {
            type: String,
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        max_postfix: {
            type: String,
            default: null
        },
        postfix: {
            type: String,
            default: null
        },
        input_fields_addon: {
            type: String,
            default: null
        },
    },
    computed: {
        from: function() {
            if(!this.val){
                return this.min;
            }
            return parseFloat(this.val.split(";")[0]);
        },
        to: function() {
            if(!this.val){
                return this.max;
            }
            return parseFloat(this.val.split(";")[1]);
        },
        getMin: function() {
            if(this.min<this.from){return this.min}
            else{return this.from}
        },
        getMax: function() {
            if(this.max>this.to){return this.max}
            else{return this.to}
        }
    },
    watch: {
        val: function () {
            this.$refs.histogramslider.update({from:this.from, to:this.to})
        },
    },
}
</script>