<template>
    <tbody class="valuation-subject--status-section">
        <tr class="section-title-row">
            <td
                :colspan="subject.session_list.length + 1"
            >
                {{$t("task_details:scope")}}
            </td>
        </tr>

        <tr v-if="countryScopeAvailable('legal_status') && permissions.legalStatus" class="valuation-scope-task">
            <td>
                {{$t("task_details:scope:legal_status")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'legal-status-task-status-session-'+session_idx"
                class="text-center"
            >
                <LegalStatusTaskStatus
                    :session="session"
                />
            </td>
        </tr>

        <tr v-if="countryScopeAvailable('administrative_acts') && permissions.administrativeActs" class="valuation-scope-task">
            <td>
                {{$t("task_details:scope:administrative_acts")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'administrative-acts-task-status-session-'+session_idx"
                class="text-center"
            >
                <AdministrativeActsTaskStatus
                    :session="session"
                />
            </td>
        </tr>

        <tr v-if="countryScopeAvailable('public_road_access') && permissions.publicRoadAccess" class="valuation-scope-task">
            <td>
                {{$t("task_details:scope:public_road_access")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'pra-task-status-session-'+session_idx"
                class="text-center"
            >
                <RoadAccessTaskStatus
                    :session="session"
                />
            </td>
        </tr>

        <tr v-if="countryScopeAvailable('comparable_transactions') && permissions.comparableTransactions" class="valuation-scope-task">
            <td>
                {{$t("task_details:scope:comparable_transactions")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'transactions-task-status-session-'+session_idx"
                class="text-center"
            >
                <ComparableTransactionsTaskStatus
                    :session="session"
                    :disabled="!permissions.accessExternalTransactions"
                />
            </td>
        </tr>

        <tr v-if="countryScopeAvailable('cost_method') && permissions.costMethod" class="valuation-scope-task">
            <td>
                {{$t("task_details:scope:cost_method")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'cost-method-task-status-session-'+session_idx"
                class="text-center"
            >
                <CostMethodTaskStatus
                    :session="session"
                    :disabled="!permissions.accessExternalTransactions"
                />
            </td>
        </tr>

        <tr v-if="countryScopeAvailable('comparable_adverts') && permissions.comparableAdverts" class="valuation-scope-task">
            <td>
                {{$t("task_details:scope:comparable_adverts")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'comp-adverts-task-status-session-'+session_idx"
                class="text-center"
            >
                <ComparableAdvertsTaskStatus
                    :session="session"
                />
            </td>
        </tr>

        <tr v-if="countryScopeAvailable('income_based_approach') && permissions.incomeBasedApproach & getCountryPlugin('INCOME_BASED_APPROACH_ENABLED')" class="valuation-scope-task">
            <td>
                {{$t("task_details:scope:income_based_approach")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'income-based-approach-task-status-session-'+session_idx"
                class="text-center"
            >
                <NDZNTaskStatus
                    :session="session"
                />
            </td>
        </tr>

        <tr v-if="countryScopeAvailable('esg_report') && permissions.esgReport" class="valuation-scope-task">
            <td>
                ESG
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'ee-esg-report-session-'+session_idx"
                class="text-center"
            >
                <ESGReportTaskStatus
                    :session="session"
                />
            </td>
        </tr>
        <tr v-if="countryScopeAvailable('region_analytics_dashboard') && permissions.marketAnalysis" class="valuation-scope-task">
            <td>
                {{$t("task_details:scope:region_analytics_dashboard")}}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'analysis-dashboard-task-status-session-'+session_idx"
                class="text-center"
            >
                <AnalyticsDashboardTaskStatus
                    :session="session"
                />
            </td>
        </tr>
        <tr v-if="countryScopeAvailable('document_cadastral_plan') && permissions.documentCadastralPlan" class="valuation-scope-task">
            <td>
                {{ $t("task_details:scope:cadastral_plan") }}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'cadastral_plan-'+session_idx"
                class="text-center"
            >
                <LegalDocumentTaskStatus
                    :session="session"
                    type="cadastral_plan"
                />
            </td>
        </tr>
        <tr v-if="countryScopeAvailable('document_possession_sheet') && permissions.documentPossessionSheet" class="valuation-scope-task">
            <td>
                {{ $t("task_details:scope:possession_sheet") }}
            </td>
            <td
                v-for="(session, session_idx) in subject.session_list" 
                :key="'possession_sheet-'+session_idx"
                class="text-center"
            >
                <LegalDocumentTaskStatus
                    :session="session"
                    type="possession_sheet"
                />
            </td>
        </tr>
    </tbody>
</template>

<script>
import {
    LegalStatusTaskStatus,
    AdministrativeActsTaskStatus,
    ComparableAdvertsTaskStatus,
    ComparableTransactionsTaskStatus,
    CostMethodTaskStatus,
    ESGReportTaskStatus,
    NDZNTaskStatus,
    RoadAccessTaskStatus,
    AnalyticsDashboardTaskStatus,
    LegalDocumentTaskStatus,
} from '../components/task_statuses'

import countrymixin from "@/mixins/countrymixin";
export default {
    mixins: [ countrymixin ],
    components: {
        LegalStatusTaskStatus,
        AdministrativeActsTaskStatus,
        ComparableAdvertsTaskStatus,
        ComparableTransactionsTaskStatus,
        CostMethodTaskStatus,
        ESGReportTaskStatus,
        NDZNTaskStatus,
        RoadAccessTaskStatus,
        AnalyticsDashboardTaskStatus,
        LegalDocumentTaskStatus,
    },
    model: {
        prop: 'subject',
        event: 'change'
    },
    props: {
        subject: {
            type: Object,
            default: () => {}
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        permissions: {
            type: Object,
            required: true,
        },
    },
    methods: {
        // NOTE: Not used anymore
        // countryTaskAvailable: function(task) {
        //     /*
        //     Returns true if task is available for current country
        //     */
        //     return this.isCountryPluginLoaded && this.getCountryPlugin("AMAS_AVAILABLE_TASKS").includes(task);
        // },
        countryScopeAvailable: function(task) {
            /*
            Returns true if scope is available for current country
            */
            return this.isCountryPluginLoaded && this.getCountryPlugin("AMAS_PROJECT_SCOPE")[task].available;
        },
    },
}
</script>

<style>
.valuation-subject--status-section .circle {
    display: inline-block;
}
.valuation-subject--status-section .circle-sm {
    width: 22px;
    padding-top: 4px;
    height: 22px;
    font-size: 10px!important;
}
</style>