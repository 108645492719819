<template>
    <div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div>
                    <p><strong style="text-transform: uppercase">{{$t('general:real_estate')}}</strong></p>
                    <p>{{ $t('ad:search')}}:</p>
                </div>
    
                <div class="row">
                    <div class="col-md-12 equal">
    
                        <div 
                            v-for="(choice, idx) in getReClassChoices"
                            v-bind:key="'re-class-choice-' + idx"
                            v-on:click="setFormData('re_class', choice.value)"
                            class="col-lg-4 col-2 text-center icon-selector"
                            v-bind:class="{ selected: form_data.re_class == choice.value }"
                        > 
                            <i :class="choice.icon"></i>
                            <p><strong>{{choice.label}}</strong></p>
                        </div>
                    
                    </div>
                </div>
    
                <div class="row" v-if="isSearchByReTypeEnabled">
                    <div class="col-md-12">
                        <Multiselect
                            v-model="form_data.re_type"
                            :options="getReTypeOptions"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :placeholder="$t('general:real_estate:type')"
                            :select-label="$t('general:select')"
                            :selected-label="$t('general:selected')"
                            :deselect-label="$t('general:remove')">
                            <template slot="noResult">
                              {{ $t('general:search:no_results')}}
                            </template>
                        </Multiselect>
                    </div>
                </div>
    
                <div class="row">
                    
                    <div class="col-md-12">
                        <label>{{ $t('general:size')}}</label>
                    </div>

                    <div class="col-md-12">
                        <SliderComponent
                            :val="form_data.size_range"
                            :min="0"
                            :max="500"
                            :postfix="' m²'"
                            :input_fields_addon="'m²'"
                            field-name='size_range'
                            @change="handleSliderChange('size_range', $event)"
                        />
                    </div>
                </div>
    
            </div>
    
            <div class="col-lg-4 col-md-12">
                <div>
                    <p><strong style="text-transform: uppercase">{{$t('ad:deal')}}</strong></p>
                    <p>{{ $t('ad:deal:filter')}}:</p>
                </div>
    
                <div class="row">
                    <div class="col-md-12 equal">
    
                        <div 
                            v-for="(choice, idx) in getTransactionTypeChoices"
                            :key="'transaction-type-choice-' + idx"
                            v-on:click="setFormData('transaction_type', choice.value)"
                            class="col-lg-4 col-2 text-center icon-selector"
                            v-bind:class="{ selected: form_data.transaction_type == choice.value }"
                        > 
                            <i :class="choice.icon"></i>
                            <p><strong>{{choice.label}}</strong></p>
                        </div>
                    
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>{{ $t('general:price')}}</label>
                    </div>

                    <div class="col-md-12">
                        <SliderComponent
                            :val="form_data.price_range"
                            :min="0"
                            :max="500000"
                            :postfix="' €'"
                            :input_fields_addon="'€'"
                            field-name='price_range'
                            @change="handleSliderChange('price_range', $event)"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                      <label><input type="checkbox" name="show_duplicates" v-model="form_data.show_duplicates" />{{ $t('general:show_duplicates')}}</label>
                    </div>
                </div>
    
            </div>
    
            <div class="col-lg-4">
                <div>
                    <p><strong style="text-transform: uppercase">{{ $t('general:location')}}</strong></p>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <KDrawLeafletMap
                            :fullscreen="true"
                            :geoms="form_data.geoms"
                            ref="k_draw_leafletmap"
                        />
                    </div>
                </div>
            </div>
            
        </div>
    
        <div class="row m-t-30">
            <div class="col-md-12 text-center">

                <button type="submit" class="btn btn-default btn-outline" v-on:click="resetFormData">
                    {{ $t('general:reset')}}
                </button>
    
                <button type="submit" class="btn btn-info btn-outline" v-on:click="submitFormData">
                    <i class="fa fa-search"></i> {{ $t('general:search')}}
                </button>
    
            </div>
        </div>

    </div>
</template>

<script>
import countrymixin from '@/mixins/countrymixin';

import Multiselect from 'vue-multiselect'
import SliderComponent from './SliderComponent.vue'
import KDrawLeafletMap from '@/components/k-leaflet-map/KDrawLeafletMap.vue'

// import { transformLayerToJson } from '../utils/geometry.js';

export default {
    name: 'AdvertSearchForm',
    mixins: [ countrymixin ],
    components: {
        Multiselect,
        SliderComponent,
        KDrawLeafletMap
    },
    props:  {
        input_form_data: {
            type: Object,
            default: function () {
                return {
                    re_class: "appartment",
                    transaction_type: "sale",
                    re_type: [],
                    geoms: {}
                }
            }, 
        },
    },
    data: function () {
        return {
            form_data: Object.assign({}, this.input_form_data),
            original_form_data: Object.assign({}, this.input_form_data),
            subtypeSearchEnabled: false,
        }
    },
    methods: {
        handleSliderChange: function(field, event) {
            let value = event.from + ";" + event.to;
            this.setFormData(field, value);
        },
        setFormData: function(field, value) {
            if(field=="re_class"){
                this.form_data["re_type"] = [];
            }
            this.form_data[field] = value;
        },
        submitFormData: function() {
            this.$emit('submit', this.form_data)
        },
        resetFormData: function() {
            this.form_data = Object.assign({}, this.original_form_data);
        },
    },
    computed: {
        isSearchByReTypeEnabled: function(){
            return this.getCountryPlugin("ADVERTISMENT_SEARCH__ENABLE_RE_TYPE_FIELD");
        },
        getReClassChoices: function(){
            return [
                {
                    "label": this.$t("general:apartment"),
                    "icon": "fa fa-building",
                    "value": "appartment",
                },
                {
                    "label": this.$t("general:house"),
                    "icon": "fa fa-home",
                    "value": "house",
                },
                {
                    "label": this.$t("general:office_building"),
                    "icon": "fa fa-industry",
                    "value": "business",
                },
                {
                    "label": this.$t("property_iq:parcel"),
                    "icon": "fa fa-map",
                    "value": "parcel",
                },
                {
                    "label": this.$t("comparable:parking:spot"),
                    "icon": "fa fa-car",
                    "value": "parking",
                },
            ]
        },
        getReTypeOptions: function(){
            let re_class = this.form_data.re_class;
            if(re_class=="house"){
                return [this.$t('comparable:house:atri'),this.$t('comparable:house:twin'),this.$t('comparable:house:double'),this.$t('comparable:house:detached'),this.$t('comparable:house:triplet'),this.$t('comparable:house:terraced'),this.$t('comparable:other')];
            } else if (re_class=="appartment") {
                return ["1,5-" + this.$t('comparable:apartment:rooms'),"1-"+ this.$t('comparable:apartment:rooms'),"2,5-"+ this.$t('comparable:apartment:rooms'),"2-"+ this.$t('comparable:apartment:rooms'),"3,5-"+ this.$t('comparable:apartment:rooms'),"3-"+ this.$t('comparable:apartment:rooms'),"4,5-"+ this.$t('comparable:apartment:rooms'),"4-"+ this.$t('comparable:apartment:rooms'),"5 " + this.$t('comparable:apartment:rooms_and_more'),this.$t('comparable:apartment:apartment'),this.$t('comparable:apartment:studio'),this.$t('comparable:apartment:room'),this.$t('comparable:other')]
            } else if (re_class=="business") {
                return [this.$t('comparable:business:workshop'),this.$t('comparable:business:restaurant'),this.$t('comparable:business:combined'),this.$t('comparable:business:hardware_store'),this.$t('comparable:business:medical_office'),this.$t('comparable:business:office'),this.$t('comparable:business:service_office'),this.$t('comparable:business:warehouse'),this.$t('comparable:business:office_complex'),this.$t('comparable:business:store'),this.$t('comparable:other')]
            } else if (re_class=="parcel") {
                return [this.$t('comparable:parcel:farm'),this.$t('comparable:parcel:field'),this.$t('comparable:parcel:unbuildable'),this.$t('comparable:parcel:for_investment'),this.$t('comparable:parcel:buildable'),this.$t('comparable:other')]
            } else if (re_class=="parking") {
                return [this.$t('comparable:parking:spot')]
            }
            return []
        },
        getTransactionTypeChoices: function(){
            return [
                {
                    "label": this.$t('ad:deal:sell'),
                    "icon": "fa fa-shopping-cart",
                    "value": "sale",
                },
                {
                    "label": this.$t('ad:deal:rent'),
                    "icon": "fa fa-usd",
                    "value": "rent",
                },
            ]
        },
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: center;
}

div.selected {
    background-color: #EFEFEF;
    border: 1px solid #999999;
}

.icon-selector {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 93px;
}
.icon-selector i.fa{
    font-size: 23px;
}
.icon-selector p{
    font-size: 13px;
    margin: 0;
}
.icon-selector p strong{
    font-size: 14px;
    margin: 0;
}
.icon-selector:hover, .export-option:hover{
    border-color: #eee;
}
</style>

<style>
.multiselect__tag {
    background: #2cabe3;
}
.multiselect__tags{
    font-size: 12px;
}
.multiselect__tag-icon:hover {
    background: #4069b4;
}

.multiselect__tag-icon:after {
    content: "\D7";
    color: #000000;
    font-size: 14px;
}

.multiselect__option--highlight {
    background: #2cabe3;
}

.multiselect__option--highlight::after {
    background: #4069b4;;
}

.multiselect, .multiselect__input, .multiselect__single{
    font-size: 12px;
}

.multiselect__option{
    padding-top: 2px;
    padding-bottom: 0;
    min-height: 20px;
}
.multiselect__option:after{
    line-height: 20px;
}
</style>