import store from "../store"

export default {
    getSessionRealEstates: function(real_estates, session) {
        /*
        Returns all RealEstates in a session
        */
        return real_estates.filter(i => String(i.session_id)===String(session.id));
    },
    getSessionMainRealEstate: function(real_estates, session) {
        /*
        Returns main RealEstate in a session
        */
        return real_estates.filter(i => String(i.session_id)===String(session.id) && i.main_property)[0];
    },
    getParcelRealEstates: function(real_estates) {
        /*
        Returns parcel RealEstates
        */
        return real_estates.filter(i => i.real_estate && i.real_estate.is_parcel)
    },
    getUnitRealEstates: function(real_estates) {
        /*
        Returns unit RealEstates
        */
        return real_estates.filter(i => i.real_estate && !i.real_estate.is_parcel)
    },

    getRealEstateType: function(real_estate) {
        /*
        Returns RealEstate type in {id, display} format
        */
        if(real_estate.custom_type !== null) {
            return {
                "id": real_estate.custom_type,
                "display": this._mapRealEstateTypeIdToDisplay(real_estate.custom_type, real_estate.real_estate.is_parcel)
            }
        } else {
            return {
                "id": this._mapRealEstateTypeDisplayToId(real_estate.real_estate.re_type, real_estate.real_estate.is_parcel),
                "display": real_estate.real_estate.re_type
            }
        }
    },
    _mapRealEstateTypeDisplayToId: function(re_type, is_parcel) {
        // Maps "Stanovanje v enostanovanjski stavbi" to "5000"
        let re_map = store.state.realEstateTypeMap[is_parcel ? "parcel" : "unit"];
        let text_to_id = re_map.reduce(function(map, obj) {
            map[obj.text] = obj.value;
            return map;
        }, {});
        return text_to_id[re_type];
    },
    _mapRealEstateTypeIdToDisplay: function(re_type_id, is_parcel) {
        // Maps "5000" to "Stanovanje v enostanovanjski stavbi"
        let re_map = store.state.realEstateTypeMap[is_parcel ? "parcel" : "unit"];
        let id_to_text = re_map.reduce(function(map, obj) {
            map[obj.value] = obj.text;
            return map;
        }, {});
        return id_to_text[re_type_id];
    },
    getRealEstateSize: function(real_estate) {
        /*
        Returns RealEstate size that should be considered in total size.
        */
        let size = real_estate.custom_size ? real_estate.custom_size : real_estate.real_estate.size;
        return Number(size).toFixed(2);
    },

    getRealEstateNetSize: function(real_estate) {
        /*
        Returns RealEstate size that should be considered in total size.
        */
        let size = real_estate.custom_size ? real_estate.custom_size : real_estate.real_estate.net_size;
        return Number(size).toFixed(2);
    },

    getDefaultMainType: function(real_estates, is_parcel) {
        /*
        This is a JS implementation of "AnalysisSession.__get_default_main_type__" method
        Returns default (not custom) main type to be considered for either parcels or units.
        */
        let main_real_estate = real_estates[0];
        if(!main_real_estate || !main_real_estate.real_estate) return null

        let main_type;
        if(main_real_estate.real_estate.is_parcel===is_parcel) {
            main_type = this.getRealEstateType(main_real_estate);
        } else {
            let grouped = {}
            let re_type;
            let main_type_id;
            real_estates = is_parcel ? this.getParcelRealEstates(real_estates) : this.getUnitRealEstates(real_estates);
            real_estates.forEach(re => {
                re_type = this.getRealEstateType(re);
                if(!Object.keys(grouped).includes(re_type.id)){
                    grouped[re_type.id] = 0;
                }
                grouped[re_type.id] += this.getRealEstateSize(re);
            })
            if (Object.keys(grouped).length > 0) {
                main_type_id = Object.keys(grouped).reduce((a, b) => grouped[a] > grouped[b] ? a : b);
            }
            main_type = {
                "id": main_type_id,
                "display": this._mapRealEstateTypeIdToDisplay(main_type_id, is_parcel)
            }
        }
        return main_type;
    },
    getSumRealEstateSizes: function(real_estates) {
        /*
        Returns sum of all RealEstate sizes in session.
        */
        return real_estates.reduce((total, real_estate) => {
            return total + this.getRealEstateSize(real_estate)
        }, 0)
    },

    getMainType: function(session, real_estates) {
        let main_real_estate = this.getSessionMainRealEstate(real_estates, session);
        let is_parcel = main_real_estate.real_estate.is_parcel;
        let main_type_id, main_type;
        if (is_parcel && session.custom_main_parcel_type) {
            main_type_id = session.custom_main_parcel_type;
            main_type = {
                "id": main_type_id,
                "display": this._mapRealEstateTypeIdToDisplay(main_type_id, is_parcel)
            }
        } else if (!is_parcel && session.custom_main_unit_type) {
            main_type_id = session.custom_main_unit_type;
            main_type = {
                "id": main_type_id,
                "display": this._mapRealEstateTypeIdToDisplay(main_type_id, is_parcel)
            }
        } else {
            main_type = this.getDefaultMainType(real_estates, is_parcel);
        }
        return main_type;
    }
}
