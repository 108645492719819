export default {
    created() {
        this.orderItemStatuses = {
            STATUS_IN_PREPARATION: "In preparation",
            STATUS_SUBMITTED: "Submitted",
            STATUS_UPDATE_REQUESTED: "Update requested",
            STATUS_ACCEPTED: "Accepted and closed",
        }
    },
    methods: {
        checkOrderItemStatus(status, ...statuses) {
            return [...statuses].includes(status);
        }
    }
}
