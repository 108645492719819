<template>
    <order
        :orderId="orderId"
        :readOnly="false"
        :showAppraisers="false"
        :showOffers="true"
        :showMoveToDepositoryButton="true"
        @order-submitted="$emit('order-submitted', $event)"
        @order-saved="$emit('order-saved', $event)"
        @move-depository="$emit('move-depository')"
    />
</template>

<script>
import Order from "../../components/Order.vue"

export default {
    components: { Order },
    props: {
        orderId: {
            type: [ String, Number ],
            required: true
        },
    },
}
</script>
