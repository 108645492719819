<template>
    <div>
        <base-order-table
            :items="orders"
            :columns="columns"
            :loading="loading_orders"
        >
            <template v-slot:actions="{ item }">
                <button
                    class="btn btn-sm btn-info btn-outline"
                    @click="openOrderDetailsModal(item)"
                >
                    Odpri
                </button>
            </template>
        </base-order-table>

        <!--
            START Modals
        -->
        <bootstrap3-modal
            :title="getOrderTitle"
            name="orderDetailsModal"
            width="90%"
        >
            <OrderDetails
                v-if="selected_order"
                :orderId="selected_order.id"
            />
        </bootstrap3-modal>
        <!--
            END Modals
        -->
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import { Swal } from "@/components/utils/swal.alerts.js";

import BaseOrderTable from "../../components/BaseOrderTable.vue";
import OrderDetails from "./OrderDetails.vue";
import { getOrderTitle } from "../../components/utils.js";

export default {
    components: {
        BaseOrderTable,
        OrderDetails,
        "bootstrap3-modal": Bootstrap3Modal,
    },
    data() {
        return {
            orders: [],
            loading_orders: false,
            selected_order: null,
            columns: [
                {
                    field: "appraiser",
                    hidden: true,
                },
                {
                    field: "offers_amount",
                    hidden: true,
                },
            ],
        }
    },
    methods: {
        getOrders: function(){
            this.loading_orders = true;
            return ApiService
                .get("/streamline/api/order/list", {
                    params: {
                        "process": "procurement",
                    }
                })
                .then(response => {
                    this.orders = response.data;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju naročil",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading_orders = false;
                })
        },

        openOrderDetailsModal: function(order) {
            this.selected_order = order;
            this.$modal.show("orderDetailsModal");
        },
    },
    computed: {
        getOrderTitle() {
            if (!this.selected_order)
                return "Podrobnosti";
            return "Povpraševanje " + getOrderTitle(this.selected_order);
        },
    },
    mounted() {
        this.getOrders();
    }

}
</script>
